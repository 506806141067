import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "description"
    }}>{`Description`}</h1>
    <p>{`This is a type of import payment used when there is no actual payment being done in Enturs systems - for example when there needs to be a receipt which shows an amount paid, but the actual payment is done solely at the operator level.
Use of this PaymentTypeGroup requires a prearranged PaymentType String, and a confirmation from Entur that our systems are set up to handle it.
Unauthorized or non-prearranged use of this PaymentTypeGroup has serious side effects on both the sales systems and the accounting systems - including bookkeeping systems that are regulated heavily by law.`}</p>
    <h2 {...{
      "id": "constraints"
    }}>{`Constraints`}</h2>
    <h3 {...{
      "id": "get-permission-from-entur-to-use-this-workflow"
    }}>{`Get permission from Entur to use this workflow`}</h3>
    <p>{`If you have a use case which requires this workflow, please contact Entur through your main business contact, and explain your use case.
Using this workflow without first arranging with us will lead to possible data loss and errors in the bookkeeping.`}</p>
    <h3 {...{
      "id": "only-for-use-in-transaction-imports---not-in-the-normal-capture-workflow"
    }}>{`Only for use in Transaction imports - not in the normal capture workflow.`}</h3>
    <p>{`This CANNOT be used with a captured transaction, for the simple reason that in captured transactions we expect an actual payment method - and this is not one.`}</p>
    <h3 {...{
      "id": "only-use-this-paymenttypegroup-with-transactions-which-will-pay-for-entire-orders"
    }}>{`Only use this PaymentTypeGroup with Transactions which will pay for entire orders.`}</h3>
    <p>{`Agent payments CANNOT be combined with other forms of payment (like payment cards) for one order. This can lead to errors in bookkeeping and also undefined results if an order is cancelled. The only exception to this rule is a special case for Vy (rutegående alternativ transport).
Using several agent payments to pay for different versions of an order -- including crediting, is fine, but only with one transaction per order version.`}</p>
    <h3 {...{
      "id": "arrange-with-entur-directly-which-paymenttype-should-be-used"
    }}>{`Arrange with Entur directly which PaymentType should be used`}</h3>
    <p>{`The PaymentType String field will tell the accounting systems how to treat your Transactions.
When creating a transaction, use the PaymentTypeGroup "AGENT", and a PaymentType that you have agreed with either your customer contact at Entur, og directly with Team Avregning.
Please do not change the PaymentType without talking to us beforehand, as this will lead to possible errors in the bookkeeping.`}</p>
    <h2 {...{
      "id": "workflow"
    }}>{`Workflow`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Follow `}<a parentName="p" {...{
            "href": "/pages-sales-guides#complete-your-first-sale-with-the-sales-api"
          }}>{`this guide`}</a>{` as usual up to and including "Create Payment"`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a Payment Transaction, but use PaymentTypeGroup "AGENT" and a blank PaymentType`}</p>
        <p parentName="li">{` `}<inlineCode parentName="p">{`POST /sales/v1/payments/:id/transactions`}</inlineCode></p>
        <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
    {`{
        "amount": "500.00",
        "currency": "NOK",
        "paymentTypeGroup": "AGENT"
      }
    `}
    </pre>
        </ExpandablePanel>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
    {`{
          "transactionId": 355836,
          "rrn": "000000355836",
          "currency": "NOK",
          "paymentTypeGroup": "AGENT",
          "summary": {
              "remainingAmountToCapture": "500.00",
              "capturedAmount": "0.00",
              "remainingAmountToCredit": "0.00",
              "creditedAmount": "0.00"
          },
          "operationLog": [
              {
                  "timestamp": "2020-06-22T06:29:14Z",
                  "operation": "CREATE",
                  "amount": "500.00",
                  "pos": "postman",
                  "distributionChannelId": "ENT:DistributionChannel:Test"
              }
          ],
          "amount": "500.00",
          "status": "CREATED",
          "createdAt": "2020-06-22T06:29:14Z",
          "_links": {
              "import": {
                  "href": "https://api.staging.entur.io/v1/payments/358519/transactions/355836/import"
              },
              "self": {
                  "href": "https://api.staging.entur.io/sales/v1/payments/358519/transactions/355836"
              }
          }
      }
    `}
    </pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Do not create a terminal, do not capture the transaction. Instead, import the transaction at the endpoint "/{paymentId}/transactions/{transactionId}/import" using the TransactionImportRequest datatype specified in our Swagger schema,
which should be linked from `}<a parentName="p" {...{
            "href": "/pages-sales-payment-api"
          }}>{`our API documentation`}</a>{`. Remember to use the PaymentType you've agreed with Entur.`}</p>
        <p parentName="li">{`   `}<inlineCode parentName="p">{`POST /sales/v1/payments/:id/transactions/:id/import`}</inlineCode></p>
   <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
       <pre>
       {`{
           "rrn": "000000355836",
           "transactionConfirmedAt": "2020-06-22T06:29:14Z",
           "companyId": "1",
           “paymentType”: “yourAgreedUponPaymentType”,
           "transactionData": {
               "additionalProp1": "string",
               "additionalProp2": "string",
               "additionalProp3": "string"
           }
         }
       `}
       </pre>
   </ExpandablePanel>
   <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
       <pre>
       {`{
             "transactionId": 355836,
             "rrn": "000000355836",
             "currency": "NOK",
             "paymentTypeGroup": "AGENT",
             "summary": {
                 "remainingAmountToCapture": "0.00",
                 "capturedAmount": "500.00",
                 "remainingAmountToCredit": "500.00",
                 "creditedAmount": "0.00"
             },
             "operationLog": [
                 {
                     "timestamp": "2020-06-22T06:35:03Z",
                     "operation": "IMPORT",
                     "amount": "500.00",
                     "pos": "postman",
                     "distributionChannelId": "ENT:DistributionChannel:Test"
                 },
                 {
                     "timestamp": "2020-06-22T06:29:14Z",
                     "operation": "CREATE",
                     "amount": "500.00",
                     "pos": "postman",
                     "distributionChannelId": "ENT:DistributionChannel:Test"
                 },
                 {
                     "timestamp": "2018-03-07T12:20:46Z",
                     "operation": "CAPTURE",
                     "amount": "500.00",
                     "pos": "postman",
                     "distributionChannelId": "ENT:DistributionChannel:Test"
                 }
             ],
             "amount": "500.00",
             "status": "CAPTURED",
             "createdAt": "2020-06-22T06:29:14Z",
             "updatedAt": "2020-06-22T06:29:14Z",
             "transactionData": {
                 "transactionConfirmedAt": "2020-06-22T06:29:14Z",
                 "paymentType": "Coop Mastercard"
             },
             "_links": {
                 "self": {
                     "href": "https://api.staging.entur.io/sales/v1/payments/358519/transactions/355836"
                 }
             }
         }
       `}
       </pre>
   </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Continue from "Generate and Distribute ticket distributions" on the guide as usual`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      